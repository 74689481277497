<template>
  <div class="">
    <heade></heade>
    <div
      class="com"
      :style="{
        backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
      }"
    >
      <div class="com_box">
        <div>
          <div class="com-left">
            <div class="com-left-top">弟子规</div>
            <div class="com-left-foot">
              <div class="nice-scroll com_left_tit">
                <router-link to="#" class="com-left-foot-but">第一课弟子规</router-link>
                <router-link to="#" class="com-left-foot-but">第二课父母呼</router-link>
                <router-link to="#" class="com-left-foot-but">第三课事虽小</router-link>
                <router-link to="#" class="com-left-foot-but">第四课亲爱我</router-link>
              </div>
              <!-- <div
                v-for="(items, indexs) in practices.division_category"
                :key="indexs"
                class="nice-scroll"
              >
                <router-link
                  v-for="(itemst, indexst) in items.division"
                  :key="indexst"
                  :to="{
                    path: detailPath,
                    query: { id: itemst.division_content_id },
                  }"
                  :class="
                    queryWhere.id == itemst.division_content_id
                      ? 'com-left-foot-but active'
                      : 'com-left-foot-but'
                  "
                >
                  {{ itemst.name }}
                </router-link>
              </div> -->
            </div>
          </div>
          <div class="com-right">
            <div>
              <div>
                <ul class="com-right-div">
                  <!-- <li
                    v-for="(item, index) in practices.practice_tab"
                    :key="index"
                    :class="{ active: selected == index }"
                    @mouseenter="change(index)"
                  >
                    {{ item.name }}
                  </li> -->
                  <li class="active">学科理论研究</li>
                  <li>示范教案</li>
                  <li>示范课</li>
                  <li>备课资源</li>
                  <li>主题创新</li>
                </ul>
              </div>
              <div class="content">
                <!-- <div
                  class="com-right-s"
                  v-for="(item, index) in practices.practice_tab"
                  :key="index"
                  :class="{ actived_content: selected == index }"
                >
                  <div v-if="item.is_container_child == 0">
                    <div v-html="item[item.field_name]"></div>
                  </div>

                  <div v-if="item.is_container_child == 1">
                    <div v-for="(items, indexs) in item.category" :key="indexs">
                      <ul
                        class="division_ul"
                        v-for="(divisions, division_index) in items.division"
                        :key="division_index"
                      >
                        <li
                          v-if="divisions.link_url != ''"
                          @click="go(divisions.link_url)"
                        >
                          {{ divisions.name }}
                        </li>
                        <li
                          v-if="
                            divisions.from_url != '' && divisions.link_url == ''
                          "
                        >
                          <router-link
                            :to="{
                              path: pathUrl,
                              query: { id: divisions.division_content_id },
                            }"
                            >{{ divisions.name }}</router-link
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="com-btt">
          <div>
            <div>
              <!-- <router-link
                v-for="(item, index) in practiceTheme"
                :key="index"
                :to="{
                  path: path,
                  query: { id: item.id },
                }"
                :class="
                  item.id == practices.practice_course_id
                    ? 'com-bbt-item active'
                    : 'com-bbt-item'
                "
              >
                {{ item.name }}
              </router-link> -->
              <router-link to="#" class="com-bbt-item">声律启蒙（下）教研专区</router-link>
              <router-link to="#" class="com-bbt-item">声律启蒙（上）教研专区</router-link>
              <router-link to="#" class="com-bbt-item">孟子（下）教研专区</router-link>
              <router-link to="#" class="com-bbt-item">孟子（上）教研专区</router-link>
              <router-link to="#" class="com-bbt-item">论语（下）教研专区</router-link>
              <router-link to="#" class="com-bbt-item">论语（上）教研专区</router-link>
              <router-link to="#" class="com-bbt-item">中庸教研专区</router-link>
              <router-link to="#" class="com-bbt-item">大学教研专区</router-link>
              <router-link to="#" class="com-bbt-item">古典诗词欣赏教研专区</router-link>
              <router-link to="#" class="com-bbt-item">千字文教研专区</router-link>
              <router-link to="#" class="com-bbt-item">三字经教研专区</router-link>
              <router-link to="#" class="com-bbt-item">弟子规教研专区</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/sanji.css";
import "@css/foot.css";
import $ from "jquery";
import heade from "@/components/heade.vue";
import foot from "@/components/foot.vue";
import { scroll } from "@utils/scroll";
import { getPracticeTabs, practiceCoursesByModularId } from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
    practices() {
      this.$nextTick(function () {
        scroll($(".nice-scroll"));
      });
    },
  },
  props: {},
  data() {
    return {
      selected: 0,
      practices: {},
      queryWhere: {},
      modularQueryWhere: {},
      content: "",
      practiceTheme: [],
      backgroud: "",
      detailPath: "",
      path: "",
      pathUrl: "",
    };
  },
  mounted: function () {
    let that = this;
    //调用接口的方法
    // getPracticeTabs(that.queryWhere).then(function (res) {
    //   that.$set(that, "practices", res.data);
    // });

    // practiceCoursesByModularId(that.modularQueryWhere).then(function (res) {
    //   that.$set(that, "practiceTheme", res.data);
    // });
  },
  created: function () {
    this.queryWhere = this.$route.query;
    this.modularQueryWhere.modular_id = this.$route.meta.id;
    this.detailPath = this.$route.meta.detailPath;
    this.path = this.$route.meta.path;
    this.pathUrl = this.$route.meta.pathUrl;
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
  },
  methods: {
    change(index) {
      this.selected = index;
    },
    go(src) {
      window.location.href = src;
    },
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>
<style scoped>
.com {
  width: 100%;
  min-width: 1484px;
  height: 1660px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.com_box {
  width: 1484px;
  height: 1660px;
  position: relative;
  margin: 0 auto;
}
.com-left-com-item {
  width: 100%;
}
.com-right-div {
  height: 20px;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}
.com-right-s {
  display: none;
}
.com_right {
  overflow-y: auto;
}
.com-btt > div {
  width: 15px;
}

.com-btt {
  width: 940px;
  font-size: 14px;
}
.com-btt > div > div {
  width: 638px;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 193px;
  top: 8px;
}
.com_right {
  overflow-y: auto;
}
.com-left-foot {
  height: 350px;
  overflow-y: auto;
}
/* 隐藏滚动条 */
.com-left-foot::-webkit-scrollbar {
  display: none;
}
.com_left_tit::-webkit-scrollbar {
  display: none;
}
.com-bbt-item {
  width: 15px;
  font-size: 18px;
  line-height: 20px;
}
.com-right-but {
  width: 100%;
  height: 658px;
}
.active {
  color: #900c0c;
}
.active2 {
  color: red;
}
.actived_content {
  display: block;
}
.division_ul {
  display: flex;
  justify-content: space-between;
  margin: 40px 30px;
}
.content {
  overflow-x: auto;
}
.content::-webkit-scrollbar {
  display: none;
}

.com-left-foot-but.active {
  color: #d8c8a5;
}
.com-right {
  overflow-y: auto;
}
</style>
